import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/radicalLectures_big.jpg"
import AppStore from "../../images/appStore.png"
import GooglePlay from "../../images/GooglePlay.jpg"
import Layout from "../../components/layoutPhoenixCustom"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"

const BadgeImage = styled.img`
  width: 160px;
  margin-top: 2rem;
  margin-right: 1rem;
`

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Recapping the Controversial"
    SecondText="A look-back at our Radical Lectures series."
  >
    <SEO title="Recapping the Controversial" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Taking a Step Back
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Through the end of January into the beginning of February, our church
      examined 4 of the most controversial and divisive topics in our culture
      today. You can watch all of those sermons{" "}
      <Link to="/phoenix/sermons/radical-lectures">here.</Link>
    </ParagraphText>
    <br />
    <br />
    <ParagraphText>
      Conventional wisdom says this could be a really bad idea. Recently our
      leadership team attended a conference where one of the speakers, a highly
      respected leader, told a room full of pastors to be Switzerland…stay
      neutral, stay safe, stay out of hot-button topics. I understand why
      pastors avoid such topics because with it can come pain and hurt. Through
      this series, we had angry first-time guests, frustrated members, heated
      debate… but we also had grace, challenging of our worldviews, and openness
      to what God really says. So, let me give you a few practical reminders of
      how we can apply this to our everyday lives.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      1. All Lives Matter
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      The foundation we built this entire series on is Genesis 1:1, that God
      created the whole earth and everything in it. If that is true and God
      truly created a world of order than He has the authority to speak into it.
      That is why when people ask why does it matter what the bible says about
      our modern-day topics? The answer is because we live in a world of order
      that was created by an intelligent God of order… not simple chaos.
    </ParagraphText>
    <br />
    <br />
    <ParagraphText>
      But, on that foundation we also learn that in{" "}
      <a href="https://www.biblegateway.com/passage/?search=Genesis+1%3A26-27&version=CSB">
        Genesis 1:26-27
      </a>
      , mankind was created with uniqueness, dignity, and purpose. One of the
      biggest mistakes Christians make when arguing about topics like Abortion
      is we argue on culture’s terms and we aren’t consistent. What do I mean?
      Well, first we argue on culture’s terms by falling into the trap of
      relegating pro-choice to caring about the mom and pro-life to caring about
      the unborn baby… it is not an either or it is an and. Which leads to the
      second point, if we are pro-life we must care about the mother and the
      baby, meaning we seek to help the mom struggling with how to care for a
      baby… we must point to resources and help before and after an abortion
      takes place… too often Christians vehemently scream that life matters
      until a scared mother makes that choice and then they are called a
      murderer and thrown to the side… Jesus was interested in picking up chins
      in grace, not throwing rocks in judgement. We must be consistent from womb
      to tomb, not just in matters of abortion, but homelessness, capital
      punishment, legal injustice… we don’t pick and choose which lives matter.
    </ParagraphText>
    <HeaderText>Practical Step:</HeaderText>
    <ParagraphText>
      Find a New Life Pregnancy Center (or similar community resource) near you
      and ask how can you help.
      <br />
      <br />
      <a href="https://www.newlifepregnancy.com">www.newlifepregnancy.com</a>
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      2. Our Identity is in Christ Alone
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      We live in a day and time where the majority of our culture (both inside
      and out of the church) find their identity in their sexuality. The LGBTQ+
      community identifies who they are based on their sexual preference or the
      gender that they self-identify as. Christians have made this same mistake
      in two extremes: one extreme is we say that people are their sexuality and
      cannot change so we must accept and affirm it as who God created them to
      be or the other extreme is we call them a special sinner that is engaged
      in a “lifestyle of sin” and until they change that identity they cannot
      know Christ. The problem is these extremes are both wrong.
    </ParagraphText>
    <br />
    <br />
    <ParagraphText>
      Jackie Hill Perry, writer of Gay Girl Good God who was formerly part of
      the LGBTQ+ community says “our sexuality may be a part of who we are, but
      it is not all that we are. Humans are more than who they are sexually
      attracted to”. Our world claims that the LGBTQ+ community is born the way
      they are and their identity is unchangeable even though there has been no
      science to support such claims. However, what is seen is that many people
      struggle with Gender Dysphoria and Same-Sex Attraction that can lead to
      transgenderism, homosexuality, etc. but having a psychological struggle is
      not the same as having an unchangeable identity. When we apply this to the
      church, we must realize as Christians that anytime we find our identity in
      anything other than Christ it is a sin. All creation is born in the image
      of God and as Christians when we come back to God through His grace,{" "}
      <a href="https://www.biblegateway.com/passage/?search=john+1%3A12&version=CSB">
        John 1:12
      </a>{" "}
      tells us our identity is in Him as children of God.
    </ParagraphText>
    <HeaderText>Practical Step:</HeaderText>
    <ParagraphText>
      Search your own heart to see what you let define you outside of Jesus and
      call it what it is…sin. Get to know people in your circle of influence for
      who they are as people created in God’s image not defined and identified
      by their sexuality.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      3. COMBINING THE TWO: LIFE AND IDENTITY
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      When we apply the truths of All life having value and our identity is
      found in Christ alone we begin to answer other controversial topics in our
      culture like: refugees, immigration, and the southern border. Romans 13
      tells us that governments are institutions that God put in place and
      ultimately has the final authority and sovereignty over… so we are to
      respect and follow the laws (that are not evil and outside of God’s moral
      law) that are nations set. However, the trap we fall into especially in
      America is to think of ourselves as citizens of our country and not of
      heaven. If we truly believe both of the statements we made above then we
      will not be so preoccupied in making sure that justice of our country is
      the number one goal, rather that the love of Christ is shown to refugees
      and immigrants (both legal and illegal) that cross our paths.
    </ParagraphText>
    <HeaderText>Practical Step:</HeaderText>
    <ParagraphText>
      Don’t let your nationalism inform your theology, rather let your theology
      and commission as a Christian inform your duty as a citizen.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      4. There is an Ultimate Truth
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      All of these controversial topics boil down to one thing… is
      someone/something, ultimately right? Is there a truth that supersedes all
      our opinions and arguments? And as a Christian that answer is an emphatic
      YES! And our culture acts as if this is true as well even if they will not
      admit it. One of the trendy beliefs in our culture today is that of moral
      relativism meaning that you can’t tell me I’m wrong because I can hold to
      my own truth. The problem is this has no practical application in our
      world today: for example, if I believe killing someone for cutting me off
      on the freeway is ok and you say no that is murder, who decides what is
      right? Well, we have a law that says it is not ok and it is murder… now
      that is extreme but you can make the same case from stealing to polygamy…
      just because someone believes something isn’t wrong to them, doesn’t make
      it right. We operate on a law that stands above us.
    </ParagraphText>
    <br />
    <br />
    <ParagraphText>
      Well, that law is not isolated from God, we live in a world of laws that
      is shaped by the bible, that is shaped by a Judeo-Christian worldview that
      is informed by what God has revealed to us as truth. The idea that we can
      all hold our own truth and it’s all relative to my belief is simply
      impractical for a society and culture to operate within. Now, it’s easy
      for us to operate within the big glaring laws like murder, stealing, etc.,
      but does our life still live as though there is not a higher truth guiding
      us? Do we selfishly serve our own desires and sin instead of living by a
      standard that is set by God to get closer to Him?
    </ParagraphText>
    <HeaderText>Practical Step:</HeaderText>
    <ParagraphText>
      Do you believe that God has set an order and standard to our world that we
      should operate within? Are we living within our own rules or within Gods?
      Don’t use this as an argument to attack others, but to point out the blind
      spots of our culture and the ultimate design of our God.
    </ParagraphText>
    <div
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      <br />
      <br />
      <i>written by Pastor Andrew Bailey - March 10th, 2020</i>
    </div>
  </Layout>
)

export default IndexPage
